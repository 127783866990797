import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Material ui imports
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
//Project imports
import config from "../config";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { axiosAuth } from "../api";

const TransactionCard = (props) => {
  const navigate = useNavigate();
  const { transaction, onSave, enableEdit, enableDelete } = props;
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({
    id: 0,
    memberAccountId: 0,
    date: "2022-08-05T18:47:49.687Z",
    amount: 0,
    type: 0,
  });

  return (
    <Grid>
      {transaction != undefined ? (
        <Card
          sx={{
            mb: 2,
            backgroundColor: "#F0F0F0",
          }}
          variant="outlined"
        >
          <Grid
            sx={{
              backgroundColor: transaction.type === 3 ? "#ffcccc" : "#c2f0c2",
              textAlign: "center",
              padding: "5px",
            }}
          >
            <Typography variant="body1">
              {transaction.typeDisplay}{" "}
              {transaction.comment != null
                ? "(" + transaction.comment + ")"
                : ""}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ padding: "15px" }}
          >
            <Grid item xs={9}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <CurrencyRupeeIcon color="primary" fontSize="small" />
                  <Typography>{transaction.amount}</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <DateRangeIcon color="primary" fontSize="small" />
                  <Typography>
                    {new Date(transaction.date).toLocaleDateString("hi-IN")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              direction="column"
              justifyContent="end"
            >
              {transaction.type === 3 ? (
                <Button
                  variant="outlined"
                  disabled={!enableEdit}
                  onClick={() => {
                    navigate(
                      "/loan-form?Id=" +
                        transaction.loanId +
                        "&back=/member-account-details?Id=" +
                        transaction.memberAccountId
                    );
                  }}
                  color="primary"
                >
                  <EditIcon />
                </Button>
              ) : null}
              <Button
                variant="outlined"
                disabled={!enableDelete}
                onClick={() => {
                  setSelectedTransaction(transaction);
                  setOpenDelete(true);
                }}
                sx={{ marginTop: 2 }}
                color="error"
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        </Card>
      ) : null}
      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        fullWidth
      >
        <DialogTitle>Delete Transaction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected transaction?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              axiosAuth
                .delete("/api/Transactions/Delete/" + transaction.id)
                .then((response) => {
                  if (response.status === 200) {
                    setOpenDelete(false);
                    onSave();
                  } else {
                    console.log("Error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TransactionCard;
