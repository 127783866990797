import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Fab,
  Grid,
  Stack,
  Typography,
  Box,
  Accordion,
  InputAdornment,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";

import config from "../../../config";
import LoanForm from "./LoanForm";

import useAxiosAuth from "../../../hooks/useAxiosAuth";

const Loan = () => {
  //states
  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState();
  const [loans, setLoans] = useState();
  const [searchText, setSearchText] = useState("");
  //functions

  const getLoanData = () => {
    axiosAuth
      .get(
        "/api/Loans/GetByBhishiAccountId?bhishiAccountId=" +
          currentBhishi.id +
          "&searchText=" +
          searchText
      )
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setLoans(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLoanData();
  }, [searchText]);

  return (
    <div>
      <Grid container sx={{ padding: "20px" }} spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography textAlign="center" variant="h6">
            Loans
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => {
              navigate("/loan-form");
            }}
            startIcon={<AddIcon />}
            variant="contained"
          >
            New Loan
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            variant="outlined"
            fullWidth
            placeholder="Search by name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {loans != undefined ? (
            <>
              {loans.loans.map((loan, index) => (
                <Accordion variant="outlined" key={index} sx={{ mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      background:
                        loan.principlePending == 0 ? "#b3ffb3" : "#E8E8E8",
                      color: loan.memberType == 1 ? "#b30086" : "#0033cc",
                      fontWeight: 500,
                    }}
                  >
                    {loan.id} - {loan.memberName}
                  </AccordionSummary>
                  <AccordionDetails>
                    {loan.memberStatus === 2 ? (
                      <Typography color="error" sx={{ marginY: 1 }}>
                        Account Closed{" "}
                      </Typography>
                    ) : null}
                    <Box>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body2" fontWeight="600">
                          Loan Amount
                        </Typography>
                        <Typography>₹ {loan.amount.toFixed(0)}</Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="body2" fontWeight="600">
                          Interest Rate (%)
                        </Typography>
                        <Typography>{loan.interest.toFixed(0)}%</Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="body2" fontWeight="600">
                          Loan Given On
                        </Typography>
                        <Typography>
                          {new Date(loan.startDate).toLocaleDateString("hi-IN")}
                        </Typography>
                      </Stack>
                      {loan?.transactions.map((transaction, index) => {
                        return (
                          <Card
                            key={index}
                            sx={{ padding: 1, marginTop: 1 }}
                            variant="outlined"
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ marginTop: 1 }}
                            >
                              <Typography variant="body2" fontWeight="600">
                                Repayment Date
                              </Typography>
                              <Typography>
                                {new Date(transaction.date).toLocaleDateString(
                                  "hi-IN"
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ marginTop: 1 }}
                            >
                              <Typography variant="body2" fontWeight="600">
                                Principle Paid
                              </Typography>
                              <Typography>
                                ₹ {transaction.amount.toFixed()}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ marginTop: 1 }}
                            >
                              <Typography variant="body2" fontWeight="600">
                                Interest Paid
                              </Typography>
                              <Typography>
                                ₹ {transaction.interestOrPenalty.toFixed()}
                              </Typography>
                            </Stack>
                          </Card>
                        );
                      })}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="body2" fontWeight="600">
                          Principle Remaining
                        </Typography>
                        <Typography>
                          ₹ {loan?.principlePending.toFixed()}
                        </Typography>
                      </Stack>
                    </Box>
                  </AccordionDetails>
                  <AccordionActions>
                    <Button
                      disabled={loan.memberStatus === 2}
                      variant="outlined"
                      onClick={() => {
                        setSelectedLoan(loan);
                        navigate("/loan-repayment?Id=" + loan.id);
                      }}
                    >
                      Repayment
                    </Button>
                    <Button
                      disabled={loan.memberStatus === 2}
                      variant="outlined"
                      onClick={() => {
                        setSelectedLoan(loan);
                        navigate("/loan-form?Id=" + loan.id + "&back=/loans");
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      disabled={loan.memberStatus === 2}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setSelectedLoan(loan);
                        setOpenDelete(true);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </AccordionActions>
                </Accordion>
              ))}
            </>
          ) : (
            <Stack justifyContnet="center" alignItems="center" spacing={3}>
              <Typography variant="h5">Loading loan accounts</Typography>
              <CircularProgress />
            </Stack>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        fullWidth
      >
        <DialogTitle>Delete Loan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete loan for{" "}
            <span style={{ color: "#E90000" }}>{selectedLoan?.memberName}</span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              axiosAuth
                .delete("/api/Loans/Delete/" + selectedLoan.id)
                .then((response) => {
                  if (response.status === 200) {
                    setOpenDelete(false);
                    getLoanData();
                  } else {
                    console.log("Error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Loan;
