import React, { useRef, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

//project imports
import BhishiReport from "./BhishiReport";
import IndividualReport from "./IndividualReport";
import config from "../../../config";

//thid party imports
import { useReactToPrint } from "react-to-print";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

const Reports = () => {
  const axiosAuth = useAxiosAuth();
  const componentRef = useRef();
  const componentRefSecond = useRef();

  const { currentBhishi } = useSelector((state) => state.generalReducer);

  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [memberAccounts, setMemberAccounts] = useState();
  const [selectedMemberAccountId, setSelectedMemberAccountId] = useState();
  const [bhishiReportData, setBhishiReportData] = useState();
  const [individualReportData, setIndividualReportData] = useState();

  const handlePrintOverall = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintIndividual = useReactToPrint({
    content: () => componentRefSecond.current,
  });

  const getAllMemberAccounts = () => {
    let filterData = {
      searchText: "",
      isDeleted: false,
      skip: 0,
      take: 10000000,
      bhishiAccountId: currentBhishi.id,
    };
    console.log(filterData);
    axiosAuth
      .post("/api/MemberAccounts/GetFilterPaginated", filterData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.memberAccounts);
          setMemberAccounts(response.data.memberAccounts);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllMemberAccounts();
  }, []);

  useEffect(() => {
    if (bhishiReportData?.bhishiAccount.id > 0) {
      handlePrintOverall();
    }
  }, [bhishiReportData]);

  useEffect(() => {
    if (individualReportData?.memberAccountId > 0) {
      handlePrintIndividual();
    }
  }, [individualReportData]);

  return (
    <Grid style={{ paddingX: 2, paddingTop: 25 }}>
      <Typography textAlign="center" variant="h5">
        Report
      </Typography>

      <Divider sx={{ mt: 1, mb: 3 }} />

      <Grid container direction="row" spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={6}>
          {" "}
          <TextField
            type="date"
            fullWidth
            variant="outlined"
            label="From"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <TextField
            type="date"
            fullWidth
            variant="outlined"
            label="To"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => {
              axiosAuth
                .post(
                  "/api/BhishiAccounts/BhishiReport?bhishiAccountId=" +
                    currentBhishi.id +
                    "&fromDate=" +
                    startDate +
                    "&toDate=" +
                    endDate
                )
                .then((response) => {
                  if (response.status === 200) {
                    console.log(response.data);
                    setBhishiReportData(response.data);
                  } else {
                    console.log("Error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              //handlePrintOverall();
            }}
            variant="contained"
          >
            Generate Bhishi Report
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {memberAccounts != undefined ? (
            <Autocomplete
              fullWidth
              disablePortal
              getOptionLabel={(memberAccounts) =>
                `${memberAccounts.memberName}`
              }
              options={memberAccounts}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              noOptionsText={"No Members Found"}
              onChange={(event, value) => {
                setSelectedMemberAccountId(value?.id);
              }}
              renderOption={(props, memberAccounts) => (
                <Box component="li" {...props} key={memberAccounts.memberId}>
                  {memberAccounts.memberName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                  label="Member"
                  placeholder="Search by name"
                />
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => {
              axiosAuth
                .post(
                  "/api/MemberAccounts/MemberAccountReport?memberAccountId=" +
                    selectedMemberAccountId +
                    "&fromDate=" +
                    startDate +
                    "&toDate=" +
                    endDate
                )
                .then((response) => {
                  if (response.status === 200) {
                    console.log(response.data);
                    setIndividualReportData(response.data);
                  } else {
                    console.log("Error");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            variant="contained"
          >
            Generate Member Report
          </Button>
        </Grid>
        <div style={{ display: "none" }}>
          <BhishiReport ref={componentRef} reportData={bhishiReportData} />
        </div>
        <div style={{ display: "none" }}>
          <IndividualReport
            ref={componentRefSecond}
            reportData={individualReportData}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Reports;
