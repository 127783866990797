import React from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  AppBar,
} from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import AssessmentIcon from "@mui/icons-material/Assessment";

const BottomNavBar = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  return (
    <AppBar>
      <BottomNavigation
        sx={{
          backgroundColor: "#F0F0F0",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          onClick={() => navigate("/")}
          sx={{ margin: 0, padding: 0 }}
        />
        <BottomNavigationAction
          label="Master Members"
          icon={<GroupIcon />}
          onClick={() => navigate("/members-list")}
          sx={{ margin: 0, padding: 0 }}
        />
        <BottomNavigationAction
          label="Reports"
          icon={<AssessmentIcon />}
          onClick={() => navigate("/reports")}
          sx={{ margin: 0, padding: 0 }}
        />
      </BottomNavigation>
    </AppBar>
  );
};

export default BottomNavBar;
