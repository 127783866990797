import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Divider,
  FormControl,
  getFormLabelUtilityClasses,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import config from "../../../config";

const validationSchema = yup.object({
  bhsihiAccountId: yup.number().required("Required"),
  monthlyContribution: yup
    .number()
    .min(0, "Value cannot be negative")
    .required("Required"),
});

const MemberAccountForm = (props) => {
  const axiosAuth = useAxiosAuth();
  const { onSave, onCancel } = props;
  const [members, setMembers] = useState();

  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  const formik = useFormik({
    initialValues: {
      memberId: 0,
      bhsihiAccountId: currentBhishi.id,
      startDate: new Date(currentBhishi.startDate.toString() + "Z")
        .toISOString()
        .split("T")[0],
      endDate: new Date(currentBhishi.endDate.toString() + "Z")
        .toISOString()
        .split("T")[0],
      status: 1,
      monthlyContribution: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      axiosAuth
        .post(
          "/api/MemberAccounts/IsMemberAccountExist?memeberId=" +
            values.memberId +
            "&bhishiAccountId=" +
            currentBhishi.id
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data === true) {
              alert("This member already exists");
            } else {
              axiosAuth
                .post("/api/MemberAccounts", values)
                .then((response) => {
                  if (response.status === 200) {
                    console.log(response.data);
                    onSave();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    let filterData = {
      searchText: "",
      isDeleted: false,
      skip: 0,
      take: 100000000,
      createdBy: userId,
    };
    console.log(filterData);

    axiosAuth
      .post("/api/Members/GetFilterPaginated", filterData)
      .then((response) => {
        if (response.status === 200) {
          setMembers(response.data.members);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Grid>
      <Grid
        sx={{
          background: "#bbdefb",
          textAlign: "center",

          padding: "5px",
          mb: 1,
        }}
      >
        <Typography variant="body1">Import Member</Typography>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {members != undefined ? (
              <>
                <Autocomplete
                  disablePortal
                  id="bill-item-autocomplete"
                  getOptionLabel={(memberAccounts) => `${memberAccounts.name}`}
                  options={members}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  noOptionsText={"No Members Found"}
                  onChange={(event, value) => {
                    formik.setFieldValue("memberId", value?.id);
                  }}
                  renderOption={(props, memberAccounts) => (
                    <Box component="li" {...props} key={memberAccounts.id}>
                      {memberAccounts.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      label="Select Member"
                      placeholder="Search by name"
                    />
                  )}
                />
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} sx={{ marginY: 15 }}></Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Monthly Contribution"
              name="monthlyContribution"
              type="number"
              value={formik.values.monthlyContribution}
              onChange={formik.handleChange}
              error={
                formik.touched.monthlyContribution &&
                Boolean(formik.errors.monthlyContribution)
              }
              helperText={
                formik.touched.monthlyContribution &&
                formik.errors.monthlyContribution
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button type="submit" variant="contained" size="large">
                Submit
              </Button>
              <Button variant="outlined" onClick={onCancel} size="large">
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
export default MemberAccountForm;
