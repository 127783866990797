import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
//Material ui imports
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";

//Project imports
import MemberForm from "./MemberForm";
import config from "../../../config";

//Third pary imports
import useAxiosAuth from "../../../hooks/useAxiosAuth";
//-------------------------------------------Master Member List Page---------------------------------------------//

const Members = () => {
  //const and other declarations
  const theme = useTheme();
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();
  const pageSize = 100;
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  //states
  const [memberData, setMemberData] = useState([]);
  const [memberCount, setMemberCount] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedMember, setSelectedMember] = useState({
    id: 0,
    name: "",
    number: 0,
    personalMobileNumber: "",
  });
  const [searchText, setSearchText] = useState("");
  //functions
  const getMemberData = (skip) => {
    let filterData = {
      searchText: searchText,
      isDeleted: false,
      skip: skip,
      take: pageSize,
      createdBy: userId,
    };
    axiosAuth
      .post("/api/Members/GetFilterPaginated", filterData)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setMemberCount(response.data.count);
          setMemberData(response.data.members);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    axiosAuth
      .delete("/api/Members/Soft-Delete/" + selectedMember.id)
      .then((response) => {
        // onSave();

        setOpenDelete(false);
        getMemberData(0);
      })
      .catch((error) => {
        console.log(error);
        setOpenDelete(false);
      });
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
  };

  //useEffect methods
  useEffect(() => {
    getMemberData(0);
  }, [searchText]);

  return (
    <div>
      <Grid container spacing={3} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h6">
            Members List
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            size="large"
            onClick={() => {
              navigate("/members-form");
            }}
          >
            Add New Member
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            variant="outlined"
            fullWidth
            placeholder="Search by name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {memberData !== undefined ? (
            <Box>
              {memberData.map((row, index) => (
                <Card
                  variant="outlined"
                  sx={{
                    paddingY: 2,
                    paddingRight: 2,
                    mb: 2,
                    background: "#d6e0f5",
                  }}
                  key={index}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    columnspacing={1}
                  >
                    <Grid item xs={2} textAlign="center">
                      <Box
                        sx={{
                          backgroundColor:
                            row.type == 1 ? "#b30086" : "#1976D2",
                          paddingY: 1,
                        }}
                      >
                        <Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
                          {row.number}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={8} align="center">
                      <Stack>
                        <Typography fontWeight="bold">{row.name}</Typography>
                        <Typography color="#616161">
                          {row.personalMobileNumber}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={2}>
                      <Stack
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={1}
                      >
                        <Button
                          onClick={() => {
                            navigate("/members-form?Id=" + row.id);
                          }}
                          sx={{ bgcolor: "#bbdefb" }}
                          variant="outlined"
                        >
                          <EditIcon color="primary" fontSize="small" />
                        </Button>
                        <Button
                          sx={{ bgcolor: "#bbdefb" }}
                          variant="outlined"
                          onClick={() => {
                            setSelectedMember(row);
                            setOpenDelete(true);
                          }}
                        >
                          <DeleteIcon color="error" fontSize="small" />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </Box>
          ) : (
            <Stack justifyContnet="center" alignItems="center" spacing={3}>
              <Typography variant="h5">Loading Members</Typography>
              <CircularProgress />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {memberCount !== undefined ? (
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(memberCount / pageSize)}
              color="primary"
              size="large"
              onChange={(event, page) => {
                getMemberData((page - 1) * pageSize);
              }}
            />
          ) : null}
        </Grid>
      </Grid>
      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete member :
            <span style={{ color: "#E90000" }}>{selectedMember.name}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Members;
