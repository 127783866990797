import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

//Material ui imports
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

//Project imports
import config from "../../../config";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

const MemberAccountClosureForm = () => {
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [memberAccountData, setMemberAccountData] = useState();
  const [closingStatement, setClosingStatement] = useState();
  const [closureDate, setClosureDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const getMemberData = () => {
    if (searchParams.get("Id") != null) {
      axiosAuth
        .get("/api/MemberAccounts/" + searchParams.get("Id"))
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            let tempData = response.data;

            setMemberAccountData(tempData);
            if (response.data.status === 2) {
              setClosureDate(
                new Date(response.data.endDate.toString() + "Z")
                  .toISOString()
                  .split("T")[0]
              );
            }
          } else {
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  return (
    <>
      {memberAccountData != undefined ? (
        <Grid container spacing={3} sx={{ padding: 3 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography textAlign="center" variant="h6">
              Premature Account Closure
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card
              variant="outlined"
              align="center"
              sx={{
                background:
                  memberAccountData.member.type == 0 ? "#bbdefb" : "#ffccf2",
                padding: 1,
              }}
            >
              <Typography fontWeight={600}>
                {memberAccountData.memberName}
              </Typography>
              <Typography variant="body2">
                Mobile No.: {memberAccountData.memberPersonalMobileNumber}
              </Typography>
              <Typography variant="body2">
                Whatsapp No.: {memberAccountData.member.whatsappNumber}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="date"
              label="Closure Date *"
              onChange={(e) => {
                setClosureDate(e.target.value);
              }}
              value={closureDate}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => {
                let requestModel = {
                  memberAccountId: memberAccountData.id,
                  closingDate: closureDate,
                  bufferDays: 0,
                };
                axiosAuth
                  .post(
                    "/api/MemberAccounts/AccountClosureSummary",
                    requestModel
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      console.log(response.data);
                      setClosingStatement(response.data);
                    } else {
                      console.log("Error");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              Get Closing StateMent
            </Button>
          </Grid>
          {closingStatement != undefined ? (
            <>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                  color="error.main"
                >
                  <Typography>Total Contribution</Typography>
                  <Typography>
                    ₹ {closingStatement.totalcontribution.toFixed(0)}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                  color="error.main"
                >
                  <Typography>Dividend</Typography>
                  <Typography>₹ 0</Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                >
                  <Typography>Total Loan Given</Typography>
                  <Typography>
                    ₹ {closingStatement.totalLoanGiven.toFixed(0)}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                >
                  <Typography>Total Loan Principle Returned</Typography>
                  <Typography>
                    ₹ {closingStatement.totalPrincipleAmmountPaid.toFixed(0)}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                >
                  <Typography>Total Loan Interest Returned</Typography>
                  <Typography>
                    ₹ {closingStatement.totalInterestAmmountPaid.toFixed(0)}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                  color="success.light"
                >
                  <Typography>Total Loan Principle Pending</Typography>
                  <Typography>
                    ₹ {closingStatement.totalPrincipleAmmountPending.toFixed(0)}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                  color="success.light"
                >
                  <Typography>Total Loan Interest Pending</Typography>
                  <Typography>
                    ₹ {closingStatement.totalInterestlAmmountPending.toFixed(0)}
                  </Typography>
                </Stack>
                <Divider sx={{ marginY: 2 }} />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginY: 1 }}
                >
                  <Typography fontWeight={500} color="success">
                    Settlement Amount
                  </Typography>
                  <Typography
                    fontWeight={500}
                    color={
                      closingStatement.settlementAmount < 0
                        ? "success.light"
                        : "error.light"
                    }
                    variant="h6"
                  >
                    {closingStatement.settlementAmount > 0
                      ? "Pay:"
                      : "Receive:"}{" "}
                    ₹ {Math.abs(closingStatement.settlementAmount).toFixed(0)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {memberAccountData?.status < 2 ? (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => {
                      axiosAuth
                        .post(
                          "/api/MemberAccounts/PrematureAccountClosure-Transaction?memberAccountId=" +
                            memberAccountData.id +
                            "&settlementAmmount=" +
                            closingStatement.settlementAmount +
                            "&closingDate=" +
                            closureDate
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            navigate(-1);
                          } else {
                            console.log("Error");
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    Confirm & Submit
                  </Button>
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
      ) : (
        <Stack
          justifyContnet="center"
          alignItems="center"
          spacing={3}
          sx={{ marginTop: 10 }}
        >
          <Typography variant="h5">Loading Member Details</Typography>
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};

export default MemberAccountClosureForm;
