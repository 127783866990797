import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Material ui imports
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

//Project imports
import MonthlyContributionForm from "./MonthlyContributionForm";
import config from "../../../config";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import { useSearchParams } from "react-router-dom";
import TransactionCard from "../../../components/TransactionCard";
//Third pary imports
import ReactWhatsapp from "react-whatsapp";
//-------------------------------------------Master Member Account Detials Page---------------------------------------------//

const MemberAccountDetails = () => {
  //const and other declarations
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 50;
  const [tabValue, setTabValue] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [memberAccountData, setMemberAccountData] = useState();
  const [selectedTransaction, setSelectedTransaction] = useState();

  //functions
  const getMemberData = () => {
    if (searchParams.get("Id") != null) {
      axiosAuth
        .get("/api/MemberAccounts/" + searchParams.get("Id"))
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);

            let tempData = response.data;

            setMemberAccountData(tempData);
          } else {
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getMemberData();
  }, [tabValue]);

  const message = `
*Member Name: ${memberAccountData?.memberName}*

*Monthly Contribution Summary*
  Monthly Contribution Paid= ₹${memberAccountData?.monthlyContributionPaid}
  Monthly Contribution Remaining= ₹ ${memberAccountData?.monthlyContributionRemaining}
  Penalties Paid= ₹ ${memberAccountData?.penaltiesPaid}
  
*Loan Summary* 
  Total Loan Given= ₹ ${memberAccountData?.loanGiven}
  Principle Repayment= ₹ ${memberAccountData?.principleRepayment}
  Interest Repayment= ₹ ${memberAccountData?.interestRepayment}`;

  const whatsappNumber = "+91" + memberAccountData?.member.whatsappNumber;

  return (
    <>
      {memberAccountData != undefined ? (
        <Grid container sx={{ padding: 2 }} spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography textAlign="center" variant="h6">
              Members Details
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card
              variant="outlined"
              sx={{
                background:
                  memberAccountData.member.type == 0 ? "#bbdefb" : "#ffccf2",
                padding: 2,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ marginBottom: 1 }}
              >
                <Typography fontWeight={600}>
                  {memberAccountData.memberName}
                </Typography>
                <Typography fontWeight={600}>
                  ₹ {memberAccountData.monthlyContribution}
                </Typography>
              </Stack>
              <Typography variant="body2">
                Mobile No.: {memberAccountData.memberPersonalMobileNumber}
              </Typography>
              <Typography variant="body2">
                Whatsapp No.: {memberAccountData.member.whatsappNumber}
              </Typography>
              {memberAccountData.status === 2 ? (
                <Typography variant="body2" color="error">
                  Status: Prematurely Closed
                </Typography>
              ) : null}
            </Card>
          </Grid>

          {memberAccountData.member.type === 0 ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                size="large"
                onClick={() => {
                  navigate("/account-closure?Id=" + memberAccountData.id);
                }}
                startIcon={<CloseIcon />}
                variant="outlined"
                color="error"
              >
                {memberAccountData.status === 2
                  ? "Check Closing Statement"
                  : "Close Account"}
              </Button>
            </Grid>
          ) : null}
          {memberAccountData.member.type === 0 ? (
            <>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="large"
                  disabled={memberAccountData.status === 2}
                  onClick={() => {
                    setSelectedTransaction({
                      memberAccountId: memberAccountData.id,
                      bhishiAccountId: memberAccountData.bhishiAccountId,
                      loanId: null,
                      type: 1,
                      amount: 0,
                      monthlyContribution:
                        memberAccountData.monthlyContribution,
                      interestOrPenalty: 0,
                      date: new Date(),
                    });
                    setOpenForm(true);
                  }}
                  startIcon={<AddIcon />}
                  variant="contained"
                >
                  Monthly Contribution
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth size="large" variant="outlined">
                  <ReactWhatsapp
                    style={{ border: "none", backgroundColor: "#ffffff" }}
                    number={whatsappNumber}
                    message={message}
                  >
                    <WhatsAppIcon color="success" />
                    <Typography>Send Summary</Typography>
                  </ReactWhatsapp>
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Card variant="outlined" align="center">
                  <Grid
                    sx={{
                      background: "#bbdefb",
                      textAlign: "center",

                      padding: "5px",
                    }}
                  >
                    <Typography variant="body1">
                      Monthly Contribution Summary
                    </Typography>
                  </Grid>
                  <Grid container spacing={4} sx={{ padding: "10px" }}>
                    <Grid item xs={12} p={1}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body2" fontWeight="600">
                          Monthly Contribution Paid
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="right"
                          alignItems="center"
                        >
                          <CurrencyRupeeIcon fontSize="small" color="success" />
                          <Typography color="success">
                            {memberAccountData?.monthlyContributionPaid}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="body2" fontWeight="600">
                          Monthly Contribution Remaining
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="right"
                          alignItems="center"
                        >
                          <CurrencyRupeeIcon fontSize="small" color="primary" />
                          <Typography color="primary">
                            {memberAccountData?.monthlyContributionRemaining}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: 1 }}
                      >
                        <Typography variant="body2" fontWeight="600">
                          Penalties Paid
                        </Typography>

                        <Typography color="error">
                          {memberAccountData?.penaltiesPaid}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Card
              variant="outlined"
              align="center"
              onClick={() => {
                navigate("/loans");
              }}
            >
              <Grid
                sx={{
                  background: "#bbdefb",
                  textAlign: "center",

                  padding: "5px",
                }}
              >
                <Typography variant="body1">Loan Summary</Typography>
              </Grid>
              <Grid container spacing={4} sx={{ padding: "10px" }}>
                <Grid item xs={12} p={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2" fontWeight="600">
                      Total Loan Given
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="right"
                      alignItems="center"
                    >
                      <CurrencyRupeeIcon fontSize="small" color="success" />
                      <Typography color="success">
                        {memberAccountData?.loanGiven}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginTop: 1 }}
                  >
                    <Typography variant="body2" fontWeight="600">
                      Principle Repayment
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="right"
                      alignItems="center"
                    >
                      <CurrencyRupeeIcon fontSize="small" color="primary" />
                      <Typography color="primary">
                        {memberAccountData?.principleRepayment}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginTop: 1 }}
                  >
                    <Typography variant="body2" fontWeight="600">
                      Interest Repayment
                    </Typography>

                    <Typography color="error">
                      {memberAccountData?.interestRepayment}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            {memberAccountData.transactions.map((transaction, index) => (
              <TransactionCard
                key={index}
                transaction={transaction}
                onSave={() => {
                  getMemberData();
                }}
                enableEdit={memberAccountData.status < 2}
                enableDelete={memberAccountData.status < 2}
              />
            ))}
          </Grid>
        </Grid>
      ) : (
        <Stack justifyContnet="center" alignItems="center" spacing={3}>
          <Typography variant="h5">Loading Bhishi List</Typography>
          <CircularProgress />
        </Stack>
      )}

      <Dialog
        open={openForm}
        onClose={() => {
          setOpenForm(false);
        }}
        fullWidth
      >
        <DialogContent>
          <MonthlyContributionForm
            onSave={() => {
              setOpenForm(false);
              getMemberData();
            }}
            onCancel={() => {
              setOpenForm(false);
            }}
            selectedTransaction={selectedTransaction}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MemberAccountDetails;
