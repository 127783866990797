import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  FormControl,
  getFormLabelUtilityClasses,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import config from "../../../config";

const validationSchema = yup.object({
  amount: yup.number().required("Required"),
  date: yup
    .date("must be valid date")
    .min(new Date("01-01-1950"))
    .required("Required"),
});

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthlyContributionForm = (props) => {
  const { onSave, onCancel, selectedTransaction } = props;
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [latePenalty, setLatePenalty] = useState(0);
  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const axiosAuth = useAxiosAuth();

  const formik = useFormik({
    initialValues: {
      memberAccountId: selectedTransaction.memberAccountId,
      bhishiAccountId: selectedTransaction.bhishiAccountId,
      loanId: selectedTransaction.loanId,
      type: selectedTransaction.type,
      amount: selectedTransaction.amount,
      monthlyContribution: selectedTransaction.monthlyContribution,
      interestOrPenalty: selectedTransaction.interestOrPenalty,
      date: new Date(selectedTransaction.date.toString() + "Z")
        .toISOString()
        .split("T")[0],
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.comment =
        monthNames[selectedMonth - 1] +
        "-" +
        (selectedMonth > 8
          ? new Date(currentBhishi.startDate).getFullYear().toString()
          : (new Date(currentBhishi.startDate).getFullYear() + 1).toString());
      axiosAuth
        .post("/api/Transactions", values)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data.status == true) {
              onSave();
            } else {
              alert(response.data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const calculate = () => {
    console.log(new Date(currentBhishi.startDate).getMonth() + 1);
    let startMonth = new Date(currentBhishi.startDate).getMonth() + 1;
    let _monthDiff = 0;
    let _paymentMonth = new Date(formik.values.date).getMonth() + 1;

    if (_paymentMonth < selectedMonth) {
      _monthDiff = 12 - (selectedMonth - _paymentMonth);
    } else {
      _monthDiff = _paymentMonth - selectedMonth;
    }

    console.log(_monthDiff);

    let _penaltyAmount = formik.values.monthlyContribution * 0.05;
    if (selectedMonth === startMonth) {
      setLatePenalty(0);
    } else {
      if (
        new Date(formik.values.date).getDate() >= 1 &&
        new Date(formik.values.date).getDate() <= 15
      ) {
        setLatePenalty(_penaltyAmount * _monthDiff);
      } else {
        setLatePenalty(_penaltyAmount * (_monthDiff + 1));
      }
    }
  };

  useEffect(() => {
    calculate();
  }, [formik.values.date, selectedMonth]);

  useEffect(() => {
    formik.setFieldValue("interestOrPenalty", latePenalty);
    let finalValue =
      parseInt(formik.values.monthlyContribution) + parseInt(latePenalty);
    formik.setFieldValue("amount", finalValue);
  }, [latePenalty]);

  return (
    <Grid>
      <Grid
        sx={{
          background: "#bbdefb",
          textAlign: "center",

          padding: "5px",
          mb: 1,
        }}
      >
        <Typography variant="body1">Monthly Contribution</Typography>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Contribution Month</InputLabel>
              <Select
                label="Contribution Month"
                value={selectedMonth}
                onChange={(event) => {
                  setSelectedMonth(event.target.value);
                }}
              >
                <MenuItem value={1}>
                  Jan (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 1
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={2}>
                  Feb (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 2
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={3}>
                  Mar (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 3
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={4}>
                  Apr (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 4
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={5}>
                  May (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 5
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={6}>
                  Jun (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 6
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={7}>
                  Jul (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 7
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={8}>
                  Aug (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 8
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={9}>
                  Sep (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 9
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={10}>
                  Oct (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 10
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={11}>
                  Nov (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 11
                      ? 0
                      : 1)}
                  )
                </MenuItem>
                <MenuItem value={12}>
                  Dec (
                  {new Date(currentBhishi.startDate).getFullYear() +
                    (new Date(currentBhishi.startDate).getMonth() + 1 <= 12
                      ? 0
                      : 1)}
                  )
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="date"
              type="date"
              label="Payment Date *"
              onChange={formik.handleChange}
              value={formik.values.date}
              fullWidth
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="monthlyContribution"
              type="number"
              disabled
              label="Contribution Amount *"
              onChange={formik.handleChange}
              value={formik.values.monthlyContribution}
              fullWidth
              error={
                formik.touched.monthlyContribution &&
                Boolean(formik.errors.monthlyContribution)
              }
              helperText={
                formik.touched.monthlyContribution &&
                formik.errors.monthlyContribution
              }
            ></TextField>
            <Typography variant="h4" textAlign="center">
              +
            </Typography>
            <TextField
              type="number"
              label="Late Penalty"
              onChange={(e) => {
                setLatePenalty(e.target.value);
              }}
              value={latePenalty}
              fullWidth
            ></TextField>
            <Typography variant="h4" textAlign="center">
              =
            </Typography>
            <TextField
              name="amount"
              type="number"
              disabled
              label="Final Amount"
              onChange={formik.handleChange}
              value={formik.values.amount}
              fullWidth
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button type="submit" variant="contained" size="large">
                Submit
              </Button>
              <Button variant="outlined" onClick={onCancel} size="large">
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default MonthlyContributionForm;
