import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useReducer, useSelector } from "react-redux";

// Auth routes
import AuthLayout from "../layout/auth-layout";
import Login from "../pages/master-pages/auth/login";

//Website routes
import WebsiteLayout from "../layout/website-layout";
import SamplePage from "../pages/sample-page";
import Members from "../pages/master-pages/members/index";
import MemberForm from "../pages/master-pages/members/MemberForm";
import MembersAccounts from "../pages/master-pages/members-accounts";
import MemberAccountDetails from "../pages/master-pages/members-accounts-details";
import Summary from "../pages/master-pages/bhishi-summary";
import Loans from "../pages/master-pages/loan-page";
import Bhishis from "../pages/master-pages/bhishis";
import LoanForm from "../pages/master-pages/loan-page/LoanForm";
import LoanRepayment from "../pages/master-pages/loan-page/LoanRepayment";
import MemberAccountClosureForm from "../pages/master-pages/members-accounts-details/MemberAccountClosureForm";
import BhishiClosureForm from "../pages/master-pages/bhishis/BhishiClosureForm";
import Reports from "../pages/master-pages/reports";
import ChangePassword from "../pages/master-pages/auth/change-password";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  let isLoggedIn = false;
  if (userId != 0) {
    isLoggedIn = true;
  }

  const WebsiteRoutes = {
    path: "/",
    element: <WebsiteLayout />,
    children: [
      {
        path: "/",
        element: isLoggedIn ? <Summary /> : <Navigate to="/login" />,
      },
      {
        path: "/bhishi-list",
        element: isLoggedIn ? <Bhishis /> : <Navigate to="/login" />,
      },
      {
        path: "/bhishi-closure",
        element: isLoggedIn ? <BhishiClosureForm /> : <Navigate to="/login" />,
      },
      {
        path: "/members-list",
        element: isLoggedIn ? <Members /> : <Navigate to="/login" />,
      },
      {
        path: "/members-form",
        element: isLoggedIn ? <MemberForm /> : <Navigate to="/login" />,
      },
      {
        path: "/members-accounts",
        element: isLoggedIn ? <MembersAccounts /> : <Navigate to="/login" />,
      },
      {
        path: "/member-account-details",
        element: isLoggedIn ? (
          <MemberAccountDetails />
        ) : (
          <Navigate to="/login" />
        ),
      },
      {
        path: "/account-closure",
        element: isLoggedIn ? (
          <MemberAccountClosureForm />
        ) : (
          <Navigate to="/login" />
        ),
      },
      {
        path: "/loans",
        element: isLoggedIn ? <Loans /> : <Navigate to="/login" />,
      },
      {
        path: "/loan-form",
        element: isLoggedIn ? <LoanForm /> : <Navigate to="/login" />,
      },
      {
        path: "/loan-repayment",
        element: isLoggedIn ? <LoanRepayment /> : <Navigate to="/login" />,
      },
      {
        path: "/reports",
        element: isLoggedIn ? <Reports /> : <Navigate to="/login" />,
      },
      {
        path: "/change-password",
        element: isLoggedIn ? <ChangePassword /> : <Navigate to="/login" />,
      },
    ],
  };

  const AuthRoutes = {
    path: "/",
    element: <AuthLayout />,
    children: [{ path: "/login", element: <Login /> }],
  };

  return useRoutes([WebsiteRoutes, AuthRoutes]);
}
