import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Divider,
  FormControl,
  getFormLabelUtilityClasses,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { useFormik } from "formik";
import * as yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import config from "../../../config";

const validationSchema = yup.object({
  amount: yup.number().required("Required"),
  date: yup.date("must be valid date").required("Required"),
});

const LoanRepayment = (props) => {
  const { onSave, onCancel } = props;
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loan, setLoan] = useState();
  const [interestAmount, setInterestAmount] = useState(0);
  const [bufferDays, setBufferDays] = useState(0);
  const [duration, setDuration] = useState(0);
  const [repaymentAmount, setRepaymentAmount] = useState(0);

  const formik = useFormik({
    initialValues: {
      memberAccountId: 0,
      bhishiAccountId: 0,
      loanId: 0,
      type: 0,
      amount: 0,
      date: new Date().toISOString().split("T")[0],
      interestOrPenalty: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let tempData = {
        memberAccountId: loan.memberAccountId,
        bhishiAccountId: loan.memberAccount.bhishiAccountId,
        loanId: loan.id,
        type: 4,
        amount: values.amount,
        date: values.date,
        interestOrPenalty: values.interestOrPenalty,
      };
      console.log(tempData);
      axiosAuth
        .post("/api/Transactions", tempData)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            navigate("/loans");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (searchParams.get("Id") != null) {
      getLoanData();
    } else {
      navigate(-1);
    }
  }, []);

  const getLoanData = () => {
    axiosAuth
      .get("/api/Loans/" + searchParams.get("Id"))
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setLoan(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculate = () => {
    if (loan != undefined) {
      let _interestStartDate =
        loan.transactions.length > 0
          ? loan.transactions[0].date
          : new Date(loan.startDate);

      let _duration =
        (new Date(formik.values.date + "T00:00:00") -
          new Date(_interestStartDate)) /
          (1000 * 60 * 60 * 24) +
        1;

      let _interestSlots = Math.ceil((_duration - bufferDays) / 15);

      let _perSlotInterest = loan.interest / 2;
      console.log(_perSlotInterest);
      let _interestAmount =
        ((loan.principlePending * _perSlotInterest) / 100) * _interestSlots;

      let _interesetPaid = _interestAmount;
      let _principlePaid = repaymentAmount - _interesetPaid;

      setInterestAmount(_interestAmount);
      setDuration(_duration);
      formik.setFieldValue("amount", _principlePaid);
      formik.setFieldValue("interestOrPenalty", _interesetPaid);
    }
  };

  useEffect(() => {
    calculate();
  }, [formik.values.date, bufferDays, repaymentAmount]);

  return (
    <Grid sx={{ padding: 2 }}>
      <Grid
        sx={{
          background: "#bbdefb",
          textAlign: "center",
          padding: "5px",
          mb: 1,
        }}
      >
        <Typography variant="body1">Loan Repayment</Typography>
      </Grid>
      <Card variant="outlined" sx={{ padding: 2, backgroundColor: "#F0F0F0" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" fontWeight="600">
            Member Name
          </Typography>
          <Typography>{loan?.memberAccount.memberName}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: 1 }}
        >
          <Typography variant="body2" fontWeight="600">
            Total Loan Amount
          </Typography>
          <Typography>₹ {loan?.amount.toFixed(0)}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: 1 }}
        >
          <Typography variant="body2" fontWeight="600">
            Interest Rate (%)
          </Typography>
          <Typography>{loan?.interest.toFixed(0)}%</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: 1 }}
        >
          <Typography variant="body2" fontWeight="600">
            Loan Given On
          </Typography>
          <Typography>
            {new Date(loan?.startDate).toLocaleDateString("hi-IN")}
          </Typography>
        </Stack>
      </Card>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <TextField
              name="date"
              type="date"
              label="Repayment Date *"
              onChange={formik.handleChange}
              value={formik.values.date}
              fullWidth
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Total Duration</Typography>
              <Typography>{duration} Days</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              label="Repayment Amount"
              onChange={(e) => {
                setRepaymentAmount(e.target.value);
              }}
              value={repaymentAmount}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              label="Buffer Days"
              onChange={(event) => {
                setBufferDays(event.target.value);
              }}
              value={bufferDays}
              fullWidth
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography fontWeight={600}>Before Payment</Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Principle Remaining</Typography>
              <Typography>₹ {loan?.principlePending.toFixed(0)}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography>Payment</Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight={600}>Total Duration</Typography>
              <Typography>{duration} Days</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Interest @ {loan?.interest}%</Typography>
              <Typography>₹ {interestAmount.toFixed(0)}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Interest Paid</Typography>
              <Typography>
                ₹ {formik.values.interestOrPenalty.toFixed(0)}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Principle Paid</Typography>
              <Typography>₹ {formik.values.amount.toFixed(0)}</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight={600}>After Payment</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Prinicple Remaining</Typography>
              <Typography>
                ₹ {(loan?.principlePending - formik?.values.amount).toFixed(0)}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button type="submit" variant="contained" size="large">
                Submit
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1, { replace: true });
                }}
                size="large"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default LoanRepayment;
