import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

//Material ui imports
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

//Project imports
import config from "../../../config";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

const BhishiClosureForm = () => {
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [closingStatement, setClosingStatement] = useState();
  const [closureDate, setClosureDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [interestRate, setInterestRate] = useState(0);
  const [bhishiData, setBhishiData] = useState();

  const getBhishiData = () => {
    axiosAuth
      .get("/api/BhishiAccounts/" + searchParams.get("Id"))
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setClosureDate(
            new Date(response.data.endDate.toString() + "Z")
              .toISOString()
              .split("T")[0]
          );
          setBhishiData(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBhishiData();
  }, []);

  return (
    <>
      {bhishiData != undefined ? (
        <Grid container spacing={3} sx={{ padding: 3 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography textAlign="center" variant="h6">
              Bhishi Details
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                padding: 2,
                backgroundColor: "#1976D2",
                color: "#FFFFFF",
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight={400}>{bhishiData.name}</Typography>
                <Typography fontWeight={400}>
                  From:{" "}
                  {new Date(bhishiData.startDate).toLocaleDateString("hi-IN")}
                </Typography>
                <Typography fontWeight={400}>
                  To: {new Date(bhishiData.endDate).toLocaleDateString("hi-IN")}
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="date"
              label="Closure Date *"
              onChange={(e) => {
                setClosureDate(e.target.value);
              }}
              value={closureDate}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              label="Interest Rate *"
              onChange={(e) => {
                setInterestRate(e.target.value);
              }}
              value={interestRate}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => {
                let requestModel = {
                  bhishiAccountId: bhishiData.id,
                  interestRate: interestRate,
                  closingDate: closureDate,
                  bufferDays: 0,
                };
                console.log(requestModel);
                axiosAuth
                  .post("/api/BhishiAccounts/BhishiSettlement", requestModel)
                  .then((response) => {
                    if (response.status === 200) {
                      console.log(response.data);
                      setClosingStatement(response.data);
                    } else {
                      console.log("Error");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              Get Closing StateMent
            </Button>
          </Grid>
          <Grid item xs={12}>
            {closingStatement != undefined ? (
              <>
                {closingStatement.map((statement, index) => {
                  return (
                    <Accordion
                      variant="outlined"
                      key={index}
                      sx={{ marginBottom: 1, backgroundColor: "#F0F0F0" }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack>
                          <Typography>
                            <b>{statement.memeberAccount.memberName}</b> (
                            {statement.memeberAccount.accountNumber})
                          </Typography>
                          <Typography
                            variant="h6"
                            color={
                              statement.settlementAmount < 0
                                ? "success.light"
                                : "error.light"
                            }
                          >
                            {statement.settlementAmount > 0
                              ? "Pay:"
                              : "Receive:"}{" "}
                            ₹ {Math.abs(statement.settlementAmount).toFixed(0)}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                          color="error.main"
                        >
                          <Typography>Total Contribution</Typography>
                          <Typography>
                            ₹ {statement.totalContribution.toFixed(0)}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                          color="error.main"
                        >
                          <Typography>Dividend</Typography>
                          <Typography>
                            ₹{" "}
                            {statement.totalContributionInterestAmmount.toFixed(
                              0
                            )}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                        >
                          <Typography>Total Loan Given</Typography>
                          <Typography>
                            ₹ {statement.totalLoanTaken.toFixed(0)}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                        >
                          <Typography>Total Loan Principle Returned</Typography>
                          <Typography>
                            ₹ {statement.totalLoanPrinciplePaid.toFixed(0)}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                        >
                          <Typography>Total Loan Interest Returned</Typography>
                          <Typography>
                            ₹ {statement.totalLoanInterestPaid.toFixed(0)}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                          color="success.light"
                        >
                          <Typography>Total Loan Principle Pending</Typography>
                          <Typography>
                            ₹ {statement.totalLoanPrinciplePending.toFixed(0)}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ marginY: 1 }}
                          color="success.light"
                        >
                          <Typography>Total Loan Interest Pending</Typography>
                          <Typography>
                            ₹ {statement.totalLoanInterestPending.toFixed(0)}
                          </Typography>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <Stack
          justifyContnet="center"
          alignItems="center"
          spacing={3}
          sx={{ marginTop: 10 }}
        >
          <Typography variant="h5">Loading Bhishi Details</Typography>
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};

export default BhishiClosureForm;
