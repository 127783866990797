import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentBhishi: "",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setCurrentBhishi: (state, action) => {
      state.currentBhishi = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentBhishi } = generalSlice.actions;

export default generalSlice.reducer;
