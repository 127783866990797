import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Material ui imports
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Wave from "../../../assets/images/wave.svg";
import RupeeLogo from "../../../assets/images/rupeelogo.png";
import { useFormik } from "formik";
import * as yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import config from "../../../config";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FormatStrikethrough } from "@mui/icons-material";

//Project imports

//Third pary imports

//------------------------------------------Member Add and Edit Form------------------------------------------//
const validationSchema = yup.object({
  name: yup
    .string()
    .max(50, "Must be 50 characters or less ")
    .required("Required"),

  personalMobileNumber: yup
    .string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .max(10, "Max 10 digits are allowed"),
  whatsappNumber: yup
    .string()

    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .max(10, "Max 10 digits are allowed"),
  address: yup.string().max(50, "Must be 50 characters or less "),
  type: yup.string().required("Required"),
});
const MemberForm = () => {
  //const and other declarations
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentBhishi } = useSelector((state) => state.generalReducer);
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );

  //states
  const [memberId, setMemberId] = useState();
  const [monthlyContribution, setMonthlyContribution] = useState(0);
  const [openSuccessMsg, setOpenSuccessMsg] = useState(false);
  //functions

  //useEffect methods
  useEffect(() => {
    setMemberId(searchParams.get("Id"));
    if (searchParams.get("Id") != null) {
      axiosAuth
        .get("/api/Members/" + searchParams.get("Id"))
        .then((response) => {
          if (response.status === 200) {
            let tempData = response.data;

            formik.setValues(tempData);
          } else {
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  //Formik declaration
  const formik = useFormik({
    initialValues: {
      name: "",
      personalMobileNumber: "",
      whatsappNumber: "",
      address: "",
      type: 0,
      createdBy: userId,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.id === undefined) {
        axiosAuth
          .post(
            "/api/Members/CheckIfExists?name=" +
              values.name +
              "&createdBy=" +
              userId
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data === "exists") {
                alert(`${values.name} aleady exists`);
                return;
              } else if (response.data === "error") {
                alert(`Internal server error, try again.`);
                return;
              } else if (response.data === "doesnotexists") {
                axiosAuth
                  .post("/api/Members", values)
                  .then((response) => {
                    if (searchParams.get("type") == "full") {
                      let memberAccount = {
                        memberId: response.data.newId,
                        bhsihiAccountId: currentBhishi.id,
                        startDate: new Date(
                          currentBhishi.startDate.toString() + "Z"
                        )
                          .toISOString()
                          .split("T")[0],
                        endDate: new Date(
                          currentBhishi.endDate.toString() + "Z"
                        )
                          .toISOString()
                          .split("T")[0],
                        status: 1,
                        createdBy: userId,
                        monthlyContribution: monthlyContribution,
                      };
                      axiosAuth
                        .post("/api/MemberAccounts", memberAccount)
                        .then((response) => {
                          if (response.status === 200) {
                            setOpenSuccessMsg(true);
                            //navigate(-1);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      navigate("/members-list");
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }
            } else {
              alert(`Internal server error, try again.`);
              return;
            }
          })
          .catch(function (error) {
            console.log(error);
            return;
          });
      } else {
        axiosAuth
          .put("/api/Members/" + values.id, values)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data.message);
              navigate(-1);
            }
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },
  });

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src={Wave} alt="Wave" />
        {/* <Grid item sx={{ mt: -18 }}>
          <img src={RupeeLogo} alt="rupee-logo" />
        </Grid> */}

        <Grid item xs={12} sx={{ mt: -5 }}>
          <Typography
            variant="h5"
            color="primary.dark"
            textAlign="center"
            sx={{ fontWeight: "600" }}
          >
            {memberId === null ? "Add New Member" : "Edit Member Details"}
          </Typography>
          <Typography variant="subtitle2" textAlign="center">
            {memberId === null
              ? "Lets's add your new member to the Bhishi."
              : null}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingX: 2, paddingTop: 5 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Mobile Number"
                  name="personalMobileNumber"
                  type="text"
                  value={formik.values.personalMobileNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.personalMobileNumber &&
                    Boolean(formik.errors.personalMobileNumber)
                  }
                  helperText={
                    formik.touched.personalMobileNumber &&
                    formik.errors.personalMobileNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="WhatsApp Number (Optional)"
                  name="whatsappNumber"
                  type="text"
                  value={formik.values.whatsappNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.whatsappNumber &&
                    Boolean(formik.errors.whatsappNumber)
                  }
                  helperText={
                    formik.touched.whatsappNumber &&
                    formik.errors.whatsappNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Address (Optional)"
                  name="address"
                  type="text"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    name="type"
                    type="text"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    <MenuItem value={0}>Regular</MenuItem>
                    <MenuItem value={1}>External</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {searchParams.get("type") === "full" &&
              formik.values.type === 0 ? (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Monthly Contribution"
                    name="monthlyContribution"
                    type="number"
                    value={monthlyContribution}
                    onChange={(e) => {
                      setMonthlyContribution(e.target.value);
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button type="submit" variant="contained" size="large">
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(-1);
                    }}
                    size="large"
                  >
                    Back
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSuccessMsg}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSuccessMsg(false);
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpenSuccessMsg(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Member saved successfully!
        </Alert>
      </Snackbar> */}
      <Dialog
        open={openSuccessMsg}
        onClose={() => {
          setOpenSuccessMsg(false);
        }}
      >
        <DialogTitle>Success</DialogTitle>
        <DialogContent>Member saved successfully!</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenSuccessMsg(false);
              navigate(-1);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MemberForm;
