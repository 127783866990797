import axios from "../api";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

//project imports
import { setUser } from "../store/userSlice";
import { setCurrentBhishi } from "../store/generalSlice";
import { axiosAuth } from "../api";

const useAxiosAuth = () => {
  const { userId, userName, role, displayRole, accessToken } = useSelector(
    (state) => state.userReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
      })
    );

    let tempBhihi = {
      id: 0,
      name: "",
      startDate: "",
      endDate: "",
      monthlyContribution: 0,
    };
    dispatch(setCurrentBhishi(tempBhihi));

    navigate("/login");
  };

  useEffect(() => {
    axiosAuth.interceptors.request.use(async (req) => {
      let accessToken = localStorage.getItem("accessToken");

      req.headers["Authorization"] = `Bearer ${accessToken}`;

      const decodedToken = jwt_decode(accessToken);
      const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;

      let request = {
        accessToken: accessToken,
      };
      const response = await axios.post("/api/Authentication/refresh", request);
      if (response.data === "Expired") {
        alert("Your session exired 1, login again.");
        handleLogout();
        return;
      }
      localStorage.setItem("accessToken", response.data.accessToken);
      req.headers["Authorization"] = `Bearer ${response.data.accessToken}`;
      return req;
    });

    // return () => {
    //   axiosAuth.interceptors.request.eject(requestIntercept);
    // };
  }, [accessToken]); //add auth
  return axiosAuth;
};

export default useAxiosAuth;
