import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Divider,
  FormControl,
  getFormLabelUtilityClasses,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { useFormik } from "formik";
import * as yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import config from "../../../config";

const validationSchema = yup.object({
  memberAccountId: yup.number().required("Required"),
  amount: yup.number().required("Required"),
  startDate: yup.date("must be valid date").required("Required"),
  guarantorOneId: yup.number().required("Required"),
  guarantorTwoId: yup.number().required("Required"),
  guarantorThreeId: yup.number().required("Required"),
});

const LoanForm = (props) => {
  const { onSave, onCancel } = props;
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const { currentBhishi } = useSelector((state) => state.generalReducer);

  const [memberAccounts, setMemberAccounts] = useState();
  const [editMemberName, setEditMemberName] = useState(false);
  const [editG1Name, setEditG1Name] = useState(false);
  const [editG2Name, setEditG2Name] = useState(false);
  const [editG3Name, setEditG3Name] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loan, setLoan] = useState();

  const formik = useFormik({
    initialValues: {
      id: 0,
      memberAccountId: 0,
      memberName: "",
      amount: 0,
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      interest: 0,
      guarantorOneId: 0,
      guarantorOneName: "",
      guarantorTwoId: 0,
      guarantorTwoName: "",
      guarantorThreeId: 0,
      guarantorThreeName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      if (values.id > 0) {
        axiosAuth
          .put("/api/Loans/" + values.id, values)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              navigate(-1, { replace: true });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axiosAuth
          .post("/api/Loans", values)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              navigate(-1, { replace: true });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  });

  useEffect(() => {
    getAllMemberAccounts();
    if (searchParams.get("Id") != null) {
      getLoanData();
    } else {
      setEditMemberName(true);
      setEditG1Name(true);
      setEditG2Name(true);
      setEditG3Name(true);
    }
  }, []);

  const getAllMemberAccounts = () => {
    let filterData = {
      searchText: "",
      isDeleted: false,
      skip: 0,
      take: 10000000,
      bhishiAccountId: currentBhishi.id,
    };
    axiosAuth
      .post("/api/MemberAccounts/GetFilterPaginated", filterData)
      .then((response) => {
        if (response.status === 200) {
          setMemberAccounts(response.data.memberAccounts);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLoanData = () => {
    axiosAuth
      .get("/api/Loans/" + searchParams.get("Id"))
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          let tempLoan = {
            id: response.data.id,
            memberAccountId: response.data.memberAccountId,
            memberName: response.data.memberAccount.memberName,
            amount: response.data.amount,
            startDate: new Date(response.data.startDate.toString() + "Z")
              .toISOString()
              .split("T")[0],
            interest: response.data.interest,
            guarantorOneId: response.data.guarantorOneId,
            guarantorOneName:
              response.data.guarantorOne != null
                ? response.data.guarantorOne.memberName
                : "NA",
            guarantorTwoId: response.data.guarantorTwoId,
            guarantorTwoName:
              response.data.guarantorTwo != null
                ? response.data.guarantorTwo.memberName
                : "NA",
            guarantorThreeId: response.data.guarantorThreeId,
            guarantorThreeName:
              response.data.guarantorThree != null
                ? response.data.guarantorThree.memberName
                : "NA",
          };
          formik.setValues(tempLoan);
          console.log(editMemberName);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Grid sx={{ padding: 2 }}>
      <Grid
        sx={{
          background: "#bbdefb",
          textAlign: "center",
          padding: "5px",
          mb: 1,
        }}
      >
        <Typography variant="body1">
          {searchParams.get("Id") != null
            ? "Create New Loan"
            : "Edit Loan Details"}
        </Typography>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            {memberAccounts != undefined ? (
              <>
                <Box>
                  {editMemberName ? (
                    <Stack direction="row">
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="bill-item-autocomplete"
                        getOptionLabel={(memberAccounts) =>
                          `${memberAccounts.memberName}`
                        }
                        options={memberAccounts}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        noOptionsText={"No Members Found"}
                        onChange={(event, value) => {
                          formik.setFieldValue("memberAccountId", value?.id);
                        }}
                        renderOption={(props, memberAccounts) => (
                          <Box
                            component="li"
                            {...props}
                            key={memberAccounts.memberId}
                          >
                            {memberAccounts.memberName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            label="Member"
                            placeholder="Search by name"
                          />
                        )}
                      />
                      {searchParams.get("Id") != null ? (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEditMemberName(false);
                          }}
                          sx={{ marginLeft: 2 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* <TextField
                        variant="outlined"
                        fullWidth
                        value={formik.values.memberName}
                        readOnly
                        label="Member Name"
                        // onClick={() => {
                        //   navigate(
                        //     "/member-account-details?Id=" +
                        //       formik.values.guarantorThreeId
                        //   );
                        // }}
                      ></TextField> */}
                      <Stack>
                        <Typography sx={{ fontWeight: 600 }}>
                          Member Name:
                        </Typography>
                        <Typography>{formik.values.memberName}</Typography>
                      </Stack>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditMemberName(true);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  )}
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="startDate"
              type="date"
              label="Loan Start Date"
              onChange={formik.handleChange}
              value={formik.values.startDate}
              fullWidth
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="amount"
              type="number"
              label="Loan Amount *"
              onChange={formik.handleChange}
              value={formik.values.amount}
              fullWidth
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="interest"
              type="number"
              label="Interest (%)"
              onChange={formik.handleChange}
              value={formik.values.interest}
              fullWidth
              error={formik.touched.interest && Boolean(formik.errors.interest)}
              helperText={formik.touched.interest && formik.errors.interest}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            {memberAccounts != undefined ? (
              <>
                <Box>
                  {editG1Name ? (
                    <Stack direction="row">
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="bill-item-autocomplete"
                        getOptionLabel={(memberAccounts) =>
                          `${memberAccounts.memberName}`
                        }
                        options={memberAccounts}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        noOptionsText={"No Members Found"}
                        onChange={(event, value) => {
                          formik.setFieldValue("guarantorOneId", value?.id);
                        }}
                        renderOption={(props, memberAccounts) => (
                          <Box
                            component="li"
                            {...props}
                            key={memberAccounts.memberId}
                          >
                            {memberAccounts.memberName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            label="Select Guarantor 1"
                            placeholder="Search by name"
                          />
                        )}
                      />
                      {searchParams.get("Id") != null ? (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEditG1Name(false);
                          }}
                          sx={{ marginLeft: 2 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={formik.values.guarantorOneName}
                        readOnly
                        label="Guarantor 1"
                        onClick={() => {
                          if (formik.values.guarantorOneId != 0) {
                            navigate(
                              "/member-account-details?Id=" +
                                formik.values.guarantorOneId
                            );
                          }
                        }}
                      ></TextField>

                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditG1Name(true);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  )}
                </Box>
              </>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            {memberAccounts != undefined ? (
              <>
                <Box>
                  {editG2Name ? (
                    <Stack direction="row">
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="bill-item-autocomplete"
                        getOptionLabel={(memberAccounts) =>
                          `${memberAccounts.memberName}`
                        }
                        options={memberAccounts}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        noOptionsText={"No Members Found"}
                        onChange={(event, value) => {
                          formik.setFieldValue("guarantorTwoId", value?.id);
                        }}
                        renderOption={(props, memberAccounts) => (
                          <Box
                            component="li"
                            {...props}
                            key={memberAccounts.memberId}
                          >
                            {memberAccounts.memberName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            label="Select Guarantor 2"
                            placeholder="Search by name"
                          />
                        )}
                      />
                      {searchParams.get("Id") != null ? (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEditG2Name(false);
                          }}
                          sx={{ marginLeft: 2 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={formik.values.guarantorTwoName}
                        readOnly
                        label="Guarantor 2"
                        onClick={() => {
                          if (formik.values.guarantorTwoId != 0) {
                            navigate(
                              "/member-account-details?Id=" +
                                formik.values.guarantorTwoId
                            );
                          }
                        }}
                      ></TextField>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditG2Name(true);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  )}
                </Box>
              </>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            {memberAccounts != undefined ? (
              <>
                <Box>
                  {editG3Name ? (
                    <Stack direction="row">
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="bill-item-autocomplete"
                        getOptionLabel={(memberAccounts) =>
                          `${memberAccounts.memberName}`
                        }
                        options={memberAccounts}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        noOptionsText={"No Members Found"}
                        onChange={(event, value) => {
                          formik.setFieldValue("guarantorThreeId", value?.id);
                        }}
                        renderOption={(props, memberAccounts) => (
                          <Box
                            component="li"
                            {...props}
                            key={memberAccounts.memberId}
                          >
                            {memberAccounts.memberName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            label="Select Guarantor 3"
                            placeholder="Search by name"
                          />
                        )}
                      />
                      {searchParams.get("Id") != null ? (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEditG3Name(false);
                          }}
                          sx={{ marginLeft: 2 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={formik.values.guarantorThreeName}
                        readOnly
                        label="Guarantor 3"
                        onClick={() => {
                          if (formik.values.guarantorThreeId != 0) {
                            navigate(
                              "/member-account-details?Id=" +
                                formik.values.guarantorThreeId
                            );
                          }
                        }}
                      ></TextField>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditG3Name(true);
                        }}
                        sx={{ marginLeft: 2 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Stack>
                  )}
                </Box>
              </>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button type="submit" variant="contained" size="large">
                Submit
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                size="large"
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default LoanForm;
