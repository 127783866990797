import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//import material ui
import {
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";

//import project components
import config from "../../../../config";
import { setUser } from "../../../../store/userSlice";
import { setCurrentBhishi } from "../../../../store/generalSlice";
import Wave from "../../../../assets/images/wave.svg";

//import third party
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../../api";
import { Box } from "@mui/system";

const validationSchema = yup.object({
  userName: yup
    .string("Enter your username")
    .max(50, "Must be 50 characters or less")
    .required("User name is required"),
  password: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .required("Password is required"),
});

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      userName: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors, setStatus }) => {
      let loginRequest = {
        userName: values.userName,
        password: values.password,
      };

      dispatch(
        setUser({
          userId: 0,
          profileId: 0,
          userName: "",
          role: 0,
          displayRole: "",
          accessToken: "",
        })
      );
      localStorage.removeItem("accessToken");

      let tempBhihi = {
        id: 0,
        name: "",
        startDate: "",
        endDate: "",
        monthlyContribution: 0,
      };
      dispatch(setCurrentBhishi(tempBhihi));

      setSubmitting(true);
      axios
        .post("/api/Authentication/Signin", loginRequest)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("accessToken", response.data.accessToken);
            dispatch(setUser(response.data));
            navigate("/bhishi-list");
          } else {
            setErrors({ submit: "Invalid Credentials, Please Try Again." });
            setStatus({ success: false });
            setSubmitting(false);
          }
        })
        .catch(function (error) {
          setErrors({
            submit: error.response.data,
          });
          setStatus({ success: false });
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Box>
        <img src={Wave} alt="Wave" />
      </Box>
      <Typography
        sx={{
          marginBottom: 3,
          marginTop: -3,
          textAlign: "center",
          fontWeight: 600,
        }}
        variant="h4"
        color="secondary"
      >
        The Bhishi App
      </Typography>
      <Card elevation={5} sx={{ margin: 2, padding: 3 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LockTwoToneIcon
              sx={{ fontSize: 100, marginBottom: 1 }}
              color="primary"
            />
            <Typography
              sx={{ textAlign: "center" }}
              variant="h5"
              color="primary"
            >
              Login
            </Typography>
            <Divider sx={{ marginY: 3 }} />
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  label="User Name"
                  name="userName"
                  fullWidth
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                ></TextField>

                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    label="Password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText error id="outlined-adornment-password">
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <Grid item xs={12}>
                  {formik.errors.submit && (
                    <FormHelperText error id="outlined-adornment-password">
                      {formik.errors.submit}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Divider />
                </Grid>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={submitting}
                >
                  Sign In
                </LoadingButton>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Login;
