import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: 0,
  profileId: 0,
  userName: "",
  role: 0,
  displayRole: "",
  accessToken: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      //console.log(action.payload);
      state.userId = action.payload.userId;
      state.profileId = action.payload.profileId;
      state.userName = action.payload.userName;
      state.role = action.payload.role;
      state.displayRole = action.payload.displayRole;
      state.accessToken = action.payload.accessToken;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setAccessToken } = userSlice.actions;

export default userSlice.reducer;
