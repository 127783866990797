import * as React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  ButtonGroup,
  InputAdornment,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Divider,
  ListItemButton,
  Stack,
  Fab,
  IconButton,
} from "@mui/material";
import IconLogout from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import { useTheme } from "@mui/material/styles";

//project imports
import { setUser } from "../../store/userSlice";
import { setCurrentBhishi } from "../../store/generalSlice";

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();

  //const { userId, displayName, role } = useSelector((state) => state.userReducer);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    localStorage.removeItem("accessToken");
    dispatch(
      setUser({
        userId: 0,
        profileId: 0,
        userName: "",
        role: 0,
        displayRole: "",
      })
    );

    let tempBhihi = {
      id: 0,
      name: "",
      startDate: "",
      endDate: "",
      monthlyContribution: 0,
    };
    dispatch(setCurrentBhishi(tempBhihi));

    //window.location.reload();
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* desktop left side Logo */}
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Typography color="primary" variant="h5">
              Saving Manager
            </Typography>
          </Box>

          {/* Mobile menu */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <MenuDrawer />
          </Box> */}

          {/* mobile center Logo */}
          <Box
            sx={{ display: { xs: "flex", md: "none", flexGrow: 1 } }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Typography color="primary" variant="h5">
              Saving Manager
            </Typography>
          </Box>

          {/*desktop nav manu */}
          <Box display="flex">
            <Box>
              <Tooltip title={"Hi, " + userName}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <Typography color={theme.palette.common.white}>
                      {userName.charAt(0)}
                    </Typography>
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box sx={{ width: 230 }}>
                  <ListItem>
                    <ListItemIcon>
                      <Avatar
                        sx={{
                          mr: 2,
                          backgroundColor: theme.palette.secondary.main,
                        }}
                      >
                        <Typography color={theme.palette.common.white}>
                          {userName.charAt(0)}
                        </Typography>
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={userName}
                      secondary={displayRole}
                      secondaryTypographyProps={{
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: 14,
                        },
                      }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: 500,
                          color: theme.palette.secondary.dark,
                          fontSize: 16,
                        },
                      }}
                    ></ListItemText>
                  </ListItem>
                  <Divider sx={{ marginTop: 1 }} />
                  <ListItemButton
                    sx={{ borderRadius: `4px` }}
                    onClick={() => {
                      navigate("/change-password");
                    }}
                  >
                    <ListItemIcon>
                      <LockOpenIcon
                        stroke={1.5}
                        size="1.3rem"
                        color={theme.palette.grey[800]}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          color={theme.palette.grey[800]}
                        >
                          Change Password
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ borderRadius: `4px` }}
                    onClick={handleLogout}
                  >
                    <ListItemIcon>
                      <IconLogout
                        stroke={1.5}
                        size="1.3rem"
                        color={theme.palette.grey[800]}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          color={theme.palette.grey[800]}
                        >
                          Logout
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <Box sx={{ pb: 2 }}>
                    <Divider />
                  </Box>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
