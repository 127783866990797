import React from "react";
import { Button, Grid, IconButton, Box, Card } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import BottomNavBar from "./BottomNavBar";

const WebsiteLayout = () => {
  return (
    <Grid>
      <Grid container>
        <Header />
      </Grid>
      <Grid sx={{ marginTop: 7, marginBottom: 9, overflow: "hidden" }}>
        <Outlet />
      </Grid>
      <Grid container>
        <BottomNavBar />
      </Grid>
    </Grid>
  );
};

export default WebsiteLayout;
