import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//Material ui imports
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { setCurrentBhishi } from "../../../store/generalSlice";

//Project imports
import BhishiForm from "./BhishiForm";
import config from "../../../config";
import useAxiosAuth from "../../../hooks/useAxiosAuth";

//-------------------------------------------Master Member List Page---------------------------------------------//

const Bhishis = () => {
  //const and other declarations
  const theme = useTheme();
  const navigate = useNavigate();
  const pageSize = 3;
  const axiosAuth = useAxiosAuth();

  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.userReducer);

  //states
  const [bhishiList, setBhishiList] = useState();
  const [selectedBhishi, setSelectedBhishi] = useState({
    id: 0,
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    monthlyContribution: 0,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [openBhishiForm, setOpenBhishiForm] = useState(false);

  //functions
  const getBhishiData = (skip) => {
    axiosAuth
      .get("/api/BhishiAccounts?createdBy=" + userId)
      .then((response) => {
        if (response.status === 200) {
          setBhishiList(response.data);
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    axiosAuth
      .delete("/api/BhishiAccounts/Soft-Delete/" + selectedBhishi.id)
      .then((response) => {
        setOpenDelete(false);
        getBhishiData(0);
      })
      .catch((error) => {
        console.log(error);
        setOpenDelete(false);
      });
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
  };

  //useEffect methods
  useEffect(() => {
    getBhishiData(0);
  }, []);

  return (
    <div>
      <Grid container spacing={3} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h6">
            Bhishi List
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            size="large"
            onClick={() => {
              setSelectedBhishi({
                id: 0,
                name: "",
                startDate: new Date(),
                endDate: new Date(),
                monthlyContribution: 0,
              });
              setOpenBhishiForm(true);
            }}
          >
            Create New Bhishi
          </Button>
        </Grid>
        <Grid item xs={12}>
          {bhishiList !== undefined ? (
            <Box>
              {bhishiList.map((bhishi, index) => (
                <Card
                  variant="outlined"
                  sx={{
                    paddingY: 2,
                    paddingRight: 2,
                    mb: 2,
                    background: "#e6ffe6",
                  }}
                  key={index}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={1} textAlign="center">
                      <Box sx={{ backgroundColor: "#1976D2", padding: 1 }}>
                        <Typography sx={{ color: "#FFFFFF" }}>
                          {index + 1}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      onClick={() => {
                        let tempBhihi = {
                          id: bhishi.id,
                          name: bhishi.name,
                          startDate: bhishi.startDate,
                          endDate: bhishi.endDate,
                          monthlyContribution: bhishi.monthlyContribution,
                        };
                        dispatch(setCurrentBhishi(tempBhihi));
                        navigate("/");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        variant="h6"
                        color="primary.dark"
                        sx={{ marginBottom: 1 }}
                      >
                        {bhishi.name}
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ marginBottom: 1 }}
                      >
                        <Typography fontWeight={600}>
                          {new Date(bhishi.startDate).toLocaleDateString(
                            "hi-IN"
                          )}{" "}
                          {" - "}
                        </Typography>
                        <Typography fontWeight={600}>
                          {new Date(bhishi.endDate).toLocaleDateString("hi-IN")}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={2}>
                      <Stack
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={1}
                      >
                        <Button
                          onClick={() => {
                            setSelectedBhishi(bhishi);
                            setOpenBhishiForm(true);
                          }}
                          sx={{ bgcolor: "#bbdefb" }}
                          variant="outlined"
                        >
                          <EditIcon color="primary" fontSize="small" />
                        </Button>
                        <Button
                          sx={{ bgcolor: "#bbdefb" }}
                          variant="outlined"
                          onClick={() => {
                            setSelectedBhishi(bhishi);
                            setOpenDelete(true);
                          }}
                        >
                          <DeleteIcon color="error" fontSize="small" />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </Box>
          ) : (
            <Stack justifyContent="center" alignItems="center" spacing={3}>
              <Typography variant="h5">Loading Bhishi List</Typography>
              <CircularProgress />
            </Stack>
          )}
        </Grid>
      </Grid>
      <Dialog open={openDelete} onClose={handleCancelDelete} fullWidth>
        <DialogTitle>Delete Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete bhishi :
            <span style={{ color: "#E90000" }}>
              {new Date(selectedBhishi.startDate).toLocaleDateString("hi-IN")} -{" "}
              {new Date(selectedBhishi.endDate).toLocaleDateString("hi-IN")}
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBhishiForm}
        onClose={() => {
          setOpenBhishiForm(false);
        }}
        fullWidth
      >
        <DialogContent>
          <BhishiForm
            onSave={() => {
              setOpenBhishiForm(false);
              getBhishiData();
            }}
            onCancel={() => {
              setOpenBhishiForm(false);
            }}
            bhishi={selectedBhishi}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Bhishis;
