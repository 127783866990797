import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//import material ui
import {
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";

//import project components
import config from "../../../../config";
import { setUser } from "../../../../store/userSlice";
import Wave from "../../../../assets/images/wave.svg";

//import third party
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../../../api";
import { Box } from "@mui/system";

const validationSchema = yup.object({
  oldPassword: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .required("Password is required"),
  newPassword: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .required("Password is required"),
  confirmNewPassword: yup
    .string("Enter your password")
    .max(50, "Must be 50 characters or less ")
    .required("Password is required")
    .when("newPassword", {
      is: (newPassword) =>
        newPassword && newPassword.length > 0 ? true : false,
      then: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Password doesn't match"),
    }),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      userId: userId,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors, setStatus }) => {
      setSubmitting(true);
      axios
        .post("/api/Authentication/ChangePassword", values)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            alert("Password Changed Successfully!, Login Again.");
            localStorage.removeItem("accessToken");
            dispatch(
              setUser({
                userId: 0,
                profileId: 0,
                userName: "",
                role: 0,
                displayRole: "",
              })
            );            
            navigate("/login");
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          setErrors({
            submit: error.response.data,
          });
          setStatus({ success: false });
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Box>
        <img src={Wave} alt="Wave" />
      </Box>
      <Typography
        sx={{
          marginBottom: 3,
          marginTop: -3,
          textAlign: "center",
          fontWeight: 600,
        }}
        variant="h4"
        color="secondary"
      >
        The Bhishi App
      </Typography>
      <Card elevation={5} sx={{ margin: 2, padding: 3 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LockTwoToneIcon
              sx={{ fontSize: 50, marginBottom: 1 }}
              color="primary"
            />
            <Typography
              sx={{ textAlign: "center" }}
              variant="h5"
              color="primary"
            >
              Change Password
            </Typography>
            <Divider sx={{ marginY: 2 }} />
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-oldPassword">
                    Old Password
                  </InputLabel>
                  <OutlinedInput
                    name="oldPassword"
                    id="outlined-adornment-oldPassword"
                    type={showPassword1 ? "text" : "password"}
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword1(!showPassword1);
                          }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.oldPassword &&
                      Boolean(formik.errors.oldPassword)
                    }
                    label="Old Password"
                  />
                  {formik.touched.oldPassword && formik.errors.oldPassword && (
                    <FormHelperText error id="outlined-adornment-oldPassword">
                      {formik.errors.oldPassword}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-newPassword">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    name="newPassword"
                    id="outlined-adornment-newPassword"
                    type={showPassword2 ? "text" : "password"}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword2(!showPassword2);
                          }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    label="New Password"
                  />
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <FormHelperText error id="outlined-adornment-newPassword">
                      {formik.errors.newPassword}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-confirmNewPassword">
                    Confirm New Password
                  </InputLabel>
                  <OutlinedInput
                    name="confirmNewPassword"
                    id="outlined-adornment-confirmNewPassword"
                    type={showPassword3 ? "text" : "password"}
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword3(!showPassword3);
                          }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword3 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.confirmNewPassword &&
                      Boolean(formik.errors.confirmNewPassword)
                    }
                    label="Confirm New Password"
                  />
                  {formik.touched.confirmNewPassword &&
                    formik.errors.confirmNewPassword && (
                      <FormHelperText error id="outlined-adornment-newPassword">
                        {formik.errors.confirmNewPassword}
                      </FormHelperText>
                    )}
                </FormControl>
                <Grid item xs={12}>
                  {formik.errors.submit && (
                    <FormHelperText
                      error
                      id="outlined-adornment-confirmNewPassword"
                    >
                      {formik.errors.submit}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    type="submit"
                    loading={submitting}
                    loadingPosition="end"
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ChangePassword;
