import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { fontWeight } from "@mui/system";
import React from "react";

const contibution = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0,
  1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10,
];
const BhishiReport = React.forwardRef((props, ref) => {
  const { reportData } = props;
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const classes = {
    narrowCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "70px",
      padding: "5px",
      fontSize: "12px",
    },
    priceCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "80px",
      padding: "5px",
      fontSize: "12px",
    },
    fullCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      padding: "5px",
      fontSize: "12px",
    },
  };
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <div style={{ position: "fixed", top: "0", width: "100%" }}>
          <Card
            variant="outlined"
            sx={{
              padding: 2,
              backgroundColor: "#e3f2fd",
              mb: 8,
            }}
          >
            <Grid container rowSpacing={1}>
              <Grid item xs={3}>
                <Typography fontWeight="bold">Bhishi Name</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{reportData?.bhishiAccount.name}</Typography>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Typography fontWeight="bold">From</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {new Date(
                    reportData?.bhishiAccount.startDate
                  ).toLocaleDateString("hi-IN")}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight="bold">To</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {new Date(
                    reportData?.bhishiAccount.endDate
                  ).toLocaleDateString("hi-IN")}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <div style={{ height: "100px" }}></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TableContainer>
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#1976D2" }}>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ color: "#ffffff" }}
                          style={classes.narrowCell}
                        >
                          Ac. No.
                        </TableCell>
                        <TableCell
                          align="left"
                          style={classes.fullCell}
                          sx={{ color: "#ffffff" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Monthly Contribution
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Contribution Penalty
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Total Loan Given
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Loan Principle Returned
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Loan Interest Returned
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData?.bhishiReportRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {row.memberAccount.accountNumber}
                          </TableCell>
                          <TableCell align="left" style={classes.fullCell}>
                            {row.memberAccount.memberName}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.monthlyContribution}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.contributionPenalty}
                          </TableCell>
                          <TableCell style={classes.priceCell} align="right">
                            {row.totalLoanGiven}
                          </TableCell>
                          <TableCell style={classes.priceCell} align="right">
                            {row.loanPrincipleReturned}
                          </TableCell>
                          <TableCell style={classes.priceCell} align="right">
                            {row.loanInterestReturned}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          align="center"
                          component="th"
                          scope="row"
                          style={classes.narrowCell}
                          sx={{ fontWeight: "bold" }}
                        >
                          Total
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.bhishiReportSumRow.monthlyContribution}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.bhishiReportSumRow.contributionPenalty}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.bhishiReportSumRow.totalLoanGiven}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.bhishiReportSumRow.loanPrincipleReturned}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.bhishiReportSumRow.loanInterestReturned}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </>
      {/* <Grid sx={{ paddingX: 3, paddingY: 3 }}>
        <TableContainer>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1976D2" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "#ffffff" }}
                  style={classes.narrowCell}
                >
                  Ac. No.
                </TableCell>
                <TableCell
                  align="left"
                  style={classes.fullCell}
                  sx={{ color: "#ffffff" }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#ffffff" }}
                  style={classes.priceCell}
                >
                  Monthly Contribution
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#ffffff" }}
                  style={classes.priceCell}
                >
                  Contribution Penalty
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#ffffff" }}
                  style={classes.priceCell}
                >
                  Total Loan Given
                </TableCell>
                <TableCell
                  x
                  align="right"
                  sx={{ color: "#ffffff" }}
                  style={classes.priceCell}
                >
                  Loan Principle Returned
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#ffffff" }}
                  style={classes.priceCell}
                >
                  Loan Interest Returned
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contibution?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    style={classes.narrowCell}
                  >
                    22001
                  </TableCell>
                  <TableCell align="left" style={classes.fullCell}>
                    Sunil Ashok Mankapure
                  </TableCell>
                  <TableCell align="right" style={classes.priceCell}>
                    23000
                  </TableCell>
                  <TableCell align="right" style={classes.priceCell}>
                    23000
                  </TableCell>
                  <TableCell style={classes.priceCell} align="right">
                    23000
                  </TableCell>
                  <TableCell style={classes.priceCell} align="right">
                    23000
                  </TableCell>
                  <TableCell style={classes.priceCell} align="right">
                    23000
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid> */}
    </div>
  );
});

export default BhishiReport;
