import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
//Material ui imports
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Wave from "../../../assets/images/wave.svg";
import RupeeLogo from "../../../assets/images/rupeelogo.png";
import { useFormik } from "formik";
import * as yup from "yup";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import config from "../../../config";
import { useSearchParams } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

//Project imports

//Third pary imports

//------------------------------------------Member Add and Edit Form------------------------------------------//
const validationSchema = yup.object({
  name: yup
    .string()
    .max(20, "Name can have max 20 characters")
    .required("Required"),
  startDate: yup.date().required("Required"),
  endDate: yup.date().required("Required"),
});

const BhishiForm = (props) => {
  //const and other declarations
  const { bhishi, onCancel, onSave } = props;
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userId, userName, role, displayRole } = useSelector(
    (state) => state.userReducer
  );
  //states

  //functions

  //useEffect methods

  //Formik declaration
  const formik = useFormik({
    initialValues: {
      id: bhishi.id,
      name: bhishi.name,
      startDate: new Date(bhishi.startDate.toString() + "Z")
        .toISOString()
        .split("T")[0],
      endDate: new Date(bhishi.endDate.toString() + "Z")
        .toISOString()
        .split("T")[0],
      createdBy: userId,
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      if (values.id === 0) {
        axiosAuth
          .post("/api/BhishiAccounts", values)
          .then((response) => {
            onSave();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axiosAuth
          .put("/api/BhishiAccounts/" + values.id, values)
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              onSave();
            }
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },
  });

  return (
    <div>
      <Grid container sx={{ marginY: 2 }}>
        <Grid item xs={12}>
          <Typography color="secondary" variant="h5">
            {bhishi.id === 0 ? "Create New Bhishi" : "Edit Bhishi Details"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ marginY: 2 }} />
        </Grid>
      </Grid>
      <Grid container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                fullWidth
                variant="outlined"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="startDate"
                type="date"
                fullWidth
                variant="outlined"
                label="Start Date"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="endDate"
                type="date"
                fullWidth
                variant="outlined"
                label="End Date"
                value={formik.values.endDate}
                onChange={formik.handleChange}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                onClick={onCancel}
                variant="outlined"
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default BhishiForm;
