import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const contibution = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0,
  1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10,
];
const IndividualReport = React.forwardRef((props, ref) => {
  const { reportData } = props;
  const getPageMargins = () => {
    return `@page { margin: ${"30px"} ${"30px"} ${"30px"} ${"30px"} !important; }`;
  };
  const classes = {
    narrowCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "70px",
      padding: "5px",
      fontSize: "12px",
    },
    priceCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: "80px",
      padding: "5px",
      fontSize: "12px",
    },
    fullCell: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      padding: "5px",
      fontSize: "12px",
    },
  };
  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <div style={{ position: "fixed", top: "0", width: "100%" }}>
          <Card
            variant="outlined"
            sx={{
              padding: 2,
              backgroundColor: "#e3f2fd",
              mb: 8,
            }}
          >
            <Grid container rowSpacing={1}>
              <Grid item xs={2}>
                <Typography fontWeight="bold">Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{reportData?.memberAccount.memberName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight="bold">Account No.</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {reportData?.memberAccount.accountNumber}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight="bold">Mobile No.</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  {reportData?.memberAccount.memberPersonalMobileNumber}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <div style={{ height: "100px" }}></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TableContainer>
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#1976D2" }}>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ color: "#ffffff" }}
                          style={classes.narrowCell}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          align="left"
                          style={classes.fullCell}
                          sx={{ color: "#ffffff" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Transaction Type
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Interest or Penalty
                        </TableCell>
                        <TableCell
                          x
                          align="right"
                          sx={{ color: "#ffffff" }}
                          style={classes.priceCell}
                        >
                          Sub Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData?.memberAccountReportRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={classes.narrowCell}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="left" style={classes.fullCell}>
                            {new Date(row.transactionDate).toLocaleDateString(
                              "hi-IN"
                            )}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.transactionType}
                          </TableCell>
                          <TableCell align="right" style={classes.priceCell}>
                            {row.amount}
                          </TableCell>
                          <TableCell style={classes.priceCell} align="right">
                            {row.interestOrPenalty}
                          </TableCell>
                          <TableCell style={classes.priceCell} align="right">
                            {row.subTotal}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="right"
                          component="th"
                          scope="row"
                          style={classes.narrowCell}
                          sx={{ fontWeight: "bold" }}
                        >
                          Total
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.memberAccountReportSumRow.amount}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.memberAccountReportSumRow.interestOrPenalty}
                        </TableCell>
                        <TableCell
                          style={classes.priceCell}
                          sx={{ fontWeight: "bold" }}
                          align="right"
                        >
                          {reportData?.memberAccountReportSumRow.subTotal}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
});

export default IndividualReport;
