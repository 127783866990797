import { useEffect } from "react";

//import material ui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

//routing
import Routes from "./routes";
import { Grid } from "@mui/material";

function App() {
  return (
    <>
      <CssBaseline />
      <Routes />
    </>
  );
}

export default App;
